a {
  text-decoration: none;

  p &,
  ul &,
  li & {
    text-decoration: underline solid var(--accent) 1px;
    text-underline-offset: 0.2rem;
  }
}

img,
video {
  @include img-fluid;
}

figure {
  figcaption {
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
  }
}

body > svg {
  display: none; // Hide SVG sprite put directly in body
}

// pills
.nav-pills {
  .nav-link {
    background-color: transparent;
  }

  .nav-link.active {
    outline: 1px solid var(--link);
    background-color: transparent;
  }
}

.btn-link {
  color: currentcolor;
}

// Footer
body > footer div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: var(--gap);
}

// Status Fatal
.btn-outline-fatal {
  --bs-btn-color: var(--purple-6);
  --bs-btn-border-color: var(--purple-6);
  // --bs-btn-hover-color: inherit;
  --bs-btn-hover-bg: var(--purple-6);
}

.bg-fatal {
  background-color: var(--purple-6);
}

// Border der Card in Prüfungen mit Status "FATAL"
.border-fatal {
  border-color: var(--purple-6);
}
