@media print {
  @page {
    size: a4;

    @bottom-center {
      content: "Seite " counter(page);
      margin-bottom: 1cm;
      font-size: 7pt;
      font-family: Roboto, sans-serif;
    }
  }

  *,
  *::before,
  *::after {
    background: transparent !important;
    color: black !important;
    filter: none !important;
    text-shadow: none !important;
  }

  :root {
    background-color: white;
    color: black;
  }

  html {
    height: unset;
  }

  body {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 11pt;
    height: unset;
    margin-top: 0;

    & > header {
      margin-bottom: 1cm;
    }
  }

  .wrap {
    margin: 0;
    display: block;
    height: unset;
    min-height: unset;

    & > main {
      margin: 0;
      height: unset;
      max-width: unset;
    }
  }

  #top,
  .messages,
  .wrap > header,
  .breadcrumb,
  .pagination,
  .icon,
  button,
  .wrap > footer {
    display: none;
    visibility: hidden;
  }

  article,
  tr,
  td,
  dt,
  dd {
    break-inside: avoid-page;
  }

  h1 {
    font-size: 13pt;
    font-weight: 700;
  }

  address {
    font-style: normal;
  }

  th,
  dt,
  strong,
  b {
    font-weight: 600;
  }

  em,
  i {
    font-style: italic;
  }
}
