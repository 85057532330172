body {
  font-size: var(--font-size-fluid-0);
  font-family: --font-sans;
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-optical-sizing: auto;
  font-weight: 400;
}

@supports (font-variation-settings: normal) {
  body {
    font-variation-settings: "wght" 400;
    font-feature-settings: "calt", "kern", "dlig";
    font-synthesis: none;
  }
}

h1,
h2,
article > header,
a[href^="mailto:"] {
  hyphens: none;
}

p,
dt,
dd,
li {
  hyphens: auto;
}

h1 {
  font-variation-settings: "wght" 700;
  font-size: var(--font-size-5);
}

h2,
h3,
b,
strong,
.btn-outline {
  font-variation-settings: "wght" 600;
}

h4,
h5,
h6 {
  font-variation-settings: "wght" 500;
}

em,
i {
  font-style: italic;
}

sup {
  font-feature-settings: "sups";
}

sub {
  font-feature-settings: "subs";
}

h2 {
  font-size: var(--font-size-4);
}

h3 {
  font-size: var(--font-size-3);
}

h4 {
  font-size: var(--font-size-2);
}

@supports (text-wrap: balance) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote {
    text-wrap: balance;
  }
}
