// Import Open Props (without their .css extension)
// https://open-props.style/
@import "./node_modules/open-props/src/props.sizes";
@import "./node_modules/open-props/src/props.fonts";

// open props colors
@import "./node_modules/open-props/src/props.gray";
@import "./node_modules/open-props/src/props.blue";

// open props animations
@import "open-props-no-media/animations";
@import "./node_modules/open-props/src/props.easing";
@import "./node_modules/open-props/src/props.purple";

// logos
$logo: "../img/logo.svg" !default;
$logo-neg: "../img/logo_neg.svg" !default;

:root {
  // Grid
  --gap: 1rem;
  --col: 15rem;
  --accent1: #e75a57; // Papaya https://www.rosebikes.com/bikes/road/race/xlite
  --accent2: #ff8175; // lighter shade of Papaya
  --accent: var(--accent1);
  --border-width: calc(1rem * 0.1);
  --bs-primary: var(--blue-8);
  --middleground: var(--gray-5);

  accent-color: var(--accent);
  color-scheme: light dark;
}

// $color-mode-type: data;
// $color-mode-type: media-query;
