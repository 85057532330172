@mixin grid() {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: var(--gap);

  @supports (grid-area: auto) {
    display: grid;
    --gap: clamp(1rem, 6vw, 3rem);
  }
}

@mixin gridAuto() {
  flex-flow: row wrap;

  @supports (grid-area: auto) {
    grid-template-columns: repeat(auto-fill, minmax(var(--col), 1fr));
  }
}

@mixin gridAutoFit() {
  flex-flow: row wrap;

  @supports (grid-area: auto) {
    grid-template-columns: repeat(auto-fit, minmax(var(--col), 1fr));
  }
}

@mixin grid-reset {
  grid-column: -1/1;
}

.grid {
  @include grid;
}

.grid-auto {
  @include gridAuto;
}

.grid-reset {
  @include grid-reset;
}
