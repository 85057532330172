html,
body {
  height: 100%;
}
body {
  display: grid;
  gap: var(--gap);
  grid-template-rows: auto 1fr auto;
  scrollbar-gutter: stable;
  height: 100%;
  gap: 0;

  & > main {
    @include grid;
    @extend .container-fluid;
    align-content: flex-start;
  }
  & > footer {
    display: flex;
    @extend .container-fluid;
    justify-content: space-between;
    padding-block: var(--gap);
  }
}
