/* icons */
.icon {
  display: inline-block;
  width: var(--size-3); // 1rem
  height: var(--size-3);
  stroke: var(--accent);
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;

  // .is-active & {
  //   fill: var(--accent);
  // }

  .disabled a & {
    fill: var(--middleground);
  }

  // Wagtail Userbar icon nicht geändert
  &.wagtail-action-icon {
    fill: unset;
    stroke: transparent;
  }

  // Theme switcher
  .active & {
    display: block !important;
  }

  // Sizes
  &--md {
    width: var(--size-5); // 1.5rem
    height: var(--size-5); // 1.5rem
  }

  &--lg {
    width: var(--size-7); // 2rem
    height: var(--size-7); // 2rem
  }

  &--xl {
    width: var(--size-8); // 3rem
    height: var(--size-8); // 3rem
  }
}
